import React from 'react';
import { withTheme } from 'styled-components';
import './Portrait.css';

const Portrait = ({ theme }) => {
  const cls1 = {
    fill: theme.background_light.darken(5),
  };

  const cls14 = {
    fill: theme.accent.darken(20),
  };

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xlink="http://www.w3.org/1999/xlink"
      width="244.532"
      height="258.667"
      viewBox="0 0 244.532 258.667"
    >
      <defs>
        <clipPath id="clip-path">
          <circle
            id="Circle-Background"
            style={cls1}
            cx="110.251"
            cy="110.251"
            r="110.251"
          />
        </clipPath>
        <clipPath id="clip-path-2">
          <circle
            id="Circle-Background-2"
            data-name="Circle-Background"
            className="cls-2"
            cx="110.251"
            cy="110.251"
            r="110.251"
            transform="translate(0 0)"
          />
        </clipPath>
        <clipPath id="clip-path-3">
          <path
            id="Path_10"
            data-name="Path 10"
            className="cls-3"
            d="M113.926,132.863v16.9H117.6a66.151,66.151,0,0,1,66.151,66.151v8.269H0v-8.269a66.151,66.151,0,0,1,66.151-66.151h3.675v-16.9a51.488,51.488,0,0,1-29.12-41.1,11.027,11.027,0,0,1-9.468-10.916V67.988a11.028,11.028,0,0,1,9.188-10.873V51.451h0A51.451,51.451,0,0,1,91.876,0h0a51.451,51.451,0,0,1,51.451,51.451v5.665a11.029,11.029,0,0,1,9.188,10.873V80.851a11.027,11.027,0,0,1-9.468,10.916A51.489,51.489,0,0,1,113.926,132.863Z"
            transform="translate(0 0)"
          />
        </clipPath>
        <clipPath id="clip-path-5">
          <path
            id="Clothes"
            className="cls-4"
            d="M155.077,29.289a66.157,66.157,0,0,1,60.675,65.928v8.221H32V95.216a66.155,66.155,0,0,1,61.6-66q-.041.515-.042,1.036c0,10.9,13.78,19.735,30.779,19.735s30.779-8.836,30.779-19.735Q155.114,29.77,155.077,29.289Z"
            transform="translate(-32 -29.219)"
          />
        </clipPath>
        <clipPath id="clip-path-7">
          <path
            id="Mouth"
            className="cls-6"
            d="M35.117,14.955a17.458,17.458,0,0,0,34.705-.081A1.925,1.925,0,0,0,68.124,13h-31.2A1.959,1.959,0,0,0,35.117,14.955Z"
            transform="translate(-35.11 -13)"
          />
        </clipPath>
      </defs>
      <g id="fabian-graphic" transform="translate(8 9.45)">
        <rect
          id="background"
          className="cls-7"
          width="244.532"
          height="258.667"
          transform="translate(-8 -9.45)"
        />
        <g id="Circle" transform="translate(3.308 28.714)">
          <g id="Mask_Group_6" data-name="Mask Group 6" className="cls-8">
            <rect
              id="_Color"
              data-name="🖍Color"
              style={cls1}
              width="220.503"
              height="220.503"
            />
          </g>
        </g>
        <g id="Avataaar" transform="translate(3.308 3.907)">
          <g
            id="Mask_Group_7"
            data-name="Mask Group 7"
            className="cls-9"
            transform="translate(0 24.806)"
          >
            <g id="Body" transform="translate(18.375 -0.919)">
              <g
                id="Mask_Group_2"
                data-name="Mask Group 2"
                className="cls-10"
                transform="translate(0 0)"
              >
                <g
                  id="Skin_-03-Brown"
                  data-name="Skin/👶🏽-03-Brown"
                  transform="translate(-29.4 0)"
                >
                  <g id="Color">
                    <rect
                      id="Rectangle_9"
                      data-name="Rectangle 9"
                      className="cls-11"
                      width="242.553"
                      height="224.178"
                    />
                  </g>
                </g>
              </g>
              <g
                id="Mask_Group_5"
                data-name="Mask Group 5"
                className="cls-10"
                transform="translate(0 0)"
              >
                <path
                  id="Neck-Shadow"
                  className="cls-12"
                  d="M146.9,79v21.132a51.451,51.451,0,0,1-102.9,0v-7.35a51.451,51.451,0,1,0,102.9,0Z"
                  transform="translate(-3.575 -6.418)"
                />
              </g>
            </g>
            <g
              id="Clothing_Shirt-Crew-Neck"
              data-name="Clothing/Shirt-Crew-Neck"
              transform="translate(18.375 149.041)"
            >
              <g
                id="Mask_Group_3"
                data-name="Mask Group 3"
                className="cls-13"
                transform="translate(0 0)"
              >
                <g
                  id="Color_Palette_Gray-01"
                  data-name="Color/Palette/Gray-01"
                  transform="translate(-29.4 -26.846)"
                >
                  <rect
                    id="_Color-2"
                    data-name="🖍Color"
                    style={cls14}
                    width="242.553"
                    height="101.064"
                  />
                </g>
              </g>
              <g
                id="Mask_Group_4"
                data-name="Mask Group 4"
                className="cls-13"
                transform="translate(0 0)"
              >
                <ellipse
                  id="Ellipse_1"
                  data-name="Ellipse 1"
                  className="cls-15"
                  cx="36.415"
                  cy="24.727"
                  rx="36.415"
                  ry="24.727"
                  transform="translate(55.92 -22.313)"
                />
              </g>
            </g>
          </g>
          <g id="Face" transform="translate(69.398 70.483)">
            <g
              id="Mouth_Smile"
              data-name="Mouth/Smile"
              transform="translate(23.498 55.387)"
            >
              <path
                id="Mouth-2"
                data-name="Mouth"
                className="cls-16"
                d="M35.117,14.955a17.458,17.458,0,0,0,34.705-.081A1.925,1.925,0,0,0,68.124,13h-31.2A1.959,1.959,0,0,0,35.117,14.955Z"
                transform="translate(-35.11 -13)"
              />
              <g
                id="Mask_Group_1"
                data-name="Mask Group 1"
                className="cls-17"
                transform="translate(0 0)"
              >
                <rect
                  id="Teeth"
                  className="cls-18"
                  width="28.482"
                  height="14.7"
                  rx="5"
                  transform="translate(3.574 -10.106)"
                />
                <g id="Tongue" transform="translate(2.655 10.106)">
                  <g id="Group_8" data-name="Group 8">
                    <circle
                      id="Ellipse_2"
                      data-name="Ellipse 2"
                      className="cls-19"
                      cx="10.106"
                      cy="10.106"
                      r="10.106"
                    />
                    <circle
                      id="Ellipse_3"
                      data-name="Ellipse 3"
                      className="cls-19"
                      cx="10.106"
                      cy="10.106"
                      r="10.106"
                      transform="translate(9.188)"
                    />
                  </g>
                </g>
              </g>
            </g>
            <g
              id="Nose_Default"
              data-name="Nose/Default"
              transform="translate(29.828 39.768)"
            >
              <path
                id="Nose"
                className="cls-20"
                d="M16,8c0,4.059,4.936,7.35,11.025,7.35h0c6.089,0,11.025-3.291,11.025-7.35"
                transform="translate(-16 -8)"
              />
            </g>
            <g
              id="Eyes_Default-_"
              data-name="Eyes/Default-😀"
              transform="translate(11.453 17.718)"
            >
              <circle
                id="Eye"
                className="cls-21"
                cx="5.513"
                cy="5.513"
                r="5.513"
                transform="translate(0 0)"
              />
              <circle
                id="Eye-2"
                data-name="Eye"
                className="cls-21"
                cx="5.513"
                cy="5.513"
                r="5.513"
                transform="translate(47.776 0)"
              />
            </g>
            <g
              id="Eyebrow_Natural_Default-Natural"
              data-name="Eyebrow/Natural/Default-Natural"
              transform="translate(0 0)"
            >
              <path
                id="Eyebrow"
                className="cls-22"
                d="M12.9.193C7.605.9-.65,5.517.041,10.858a.23.23,0,0,0,.4.083C2.726,8.012,20.39,3.709,26.823,4.607c.589.082.977-.525.576-.9C23.958.455,17.641-.448,12.9.193"
                transform="translate(0.961 0) rotate(5)"
              />
              <path
                id="Eyebrow-2"
                data-name="Eyebrow"
                className="cls-22"
                d="M12.9,10.832C7.605,10.122-.65,5.508.041.167a.23.23,0,0,1,.4-.083C2.726,3.013,20.39,7.317,26.822,6.418c.589-.082.978.525.576.9-3.44,3.248-9.758,4.151-14.5,3.51"
                transform="translate(82.626 10.983) rotate(175)"
              />
            </g>
          </g>
          <g id="Top" transform="translate(52.277 0)">
            <g
              id="Top_Short-Hair_Short-Waved"
              data-name="Top/Short-Hair/Short-Waved"
              transform="translate(0)"
            >
              <g id="Group_9" data-name="Group 9">
                <path
                  id="Intersection_2"
                  data-name="Intersection 2"
                  className="cls-23"
                  d="M3.635,79.7C-1.238,67.751-.918,53.277,2.851,41.108a26.808,26.808,0,0,1,4.932-9.713,22.982,22.982,0,0,1,3.84-3.718c.711-.539,2.378-1.2,2.821-1.932.408-.672.07-2.7.252-3.518a17,17,0,0,1,2.29-4.831,32.832,32.832,0,0,1,3.781-5.333A37.343,37.343,0,0,1,50.81.07C57.7.48,64.03,2.977,70.7,4.393c6.951,1.476,12.6.461,18.947-2.585,6.733-3.231,13.76-2.31,17.223,5.036,2.82,5.983,1.624,15.2-3.343,19.753,6.981,6.426,10.849,16.554,11.242,25.927.3,7.283-.179,15.788-3,22.564-.38.912-1.706,5.689-3.553,5.724s-2.92-18.76-7.759-27.807c-1.675-3.131-12.667-10.741-13.611-15.824-2.148,2.266-11.1,7.492-22.729,8.017-25.782,1.164-35.719-8.772-36.7-10.328-1.847.09-10.892,11.389-12.848,14.405C8.559,58.545,7.179,75.09,7.175,77.59c0,.68.54,2.94.306,3.614-.374,1.079-.831,1.484-1.3,1.484C5.162,82.688,4.08,80.789,3.635,79.7Z"
                  transform="translate(1.012)"
                />
                <g
                  id="Top__Resources_Round"
                  data-name="Top/_Resources/Round"
                  transform="translate(0 71.663)"
                >
                  <g
                    id="Lennon-Glasses"
                    className="cls-24"
                    transform="translate(0.093 2)"
                  >
                    <path
                      id="Path_12"
                      data-name="Path 12"
                      className="cls-25"
                      d="M74.578,15.306a22.059,22.059,0,0,1,38.933-1.2,2.3,2.3,0,0,1,.606-.081h7.35a2.3,2.3,0,1,1,0,4.594h-6.006a22.05,22.05,0,1,1-42.816,2.6,6.87,6.87,0,0,0-13.707-.92A22.05,22.05,0,1,1,16.3,18.619H10.3a2.3,2.3,0,0,1,0-4.594h7.35a2.3,2.3,0,0,1,.606.081,22.058,22.058,0,0,1,38.823.98,10.326,10.326,0,0,1,17.5.22ZM37.4,43.425A18.375,18.375,0,1,0,19.025,25.05,18.375,18.375,0,0,0,37.4,43.425Zm56.963,0A18.375,18.375,0,1,0,75.988,25.05,18.375,18.375,0,0,0,94.364,43.425Z"
                      transform="translate(-8 -3)"
                    />
                  </g>
                  <g id="fabian-glasses" transform="translate(0.415 -0.023)">
                    <path
                      id="Path_15"
                      className="cls-26"
                      d="M106.1,10.9s-.5.1-.5.2a43.2,43.2,0,0,1,1.9,4.5h6.3a2.112,2.112,0,0,0,2.1-2.1v-.1c-.1-2.1-1.5-2.4-2.1-2.4Z"
                    />
                    <path
                      id="Path_16"
                      className="cls-26"
                      d="M9.9,10.9s.5.1.5.2c-.2.4-.7,1.4-1.1,2.4s-.8,1.8-.9,2.2H2.1A2.112,2.112,0,0,1,0,13.6v-.1c.1-2.1,1.5-2.4,2.1-2.4H9.9Z"
                    />
                    <path
                      id="Path_14"
                      className="cls-26"
                      d="M49.2,12.1a32.38,32.38,0,0,1,1.9,5.2s.7-6.1,6.9-6.1,6.8,7.1,6.8,7.1a21.2,21.2,0,0,1,2-6,10.035,10.035,0,0,0-8.7-5A10.889,10.889,0,0,0,49.2,12.1Z"
                    />
                    <path
                      id="Path_12-2"
                      data-name="Path_12"
                      className="cls-27"
                      d="M51.1,17.3C54.4,29,46.1,41,34.2,43.6a22.154,22.154,0,0,1-25.8-28l1-2.4.9-2.1A22.111,22.111,0,0,1,40.4,2.9a21.479,21.479,0,0,1,8.7,9.2s.6,1.3,1.1,2.5C50.9,16.5,50.9,16.7,51.1,17.3Zm56.5-1.7a22.049,22.049,0,1,1-42.8,2.6,18.523,18.523,0,0,1,.8-3.2c.7-1.9,1.1-2.7,1.1-2.7h0a22.072,22.072,0,0,1,38.9-1.2,19.044,19.044,0,0,1,1.1,2.2A14.488,14.488,0,0,1,107.6,15.6ZM29.5,40.4A18.4,18.4,0,1,0,11.1,22,18.415,18.415,0,0,0,29.5,40.4Zm57,0A18.35,18.35,0,1,0,68.1,22,18.415,18.415,0,0,0,86.5,40.4Z"
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default withTheme(Portrait);
